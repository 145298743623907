import React from "react";
import { has } from "lodash";
import { useRouter } from "next/router";
import { QueryClient, dehydrate } from "@tanstack/react-query";
import { generatePath } from "@gonoodle/gn-universe-utils";
import { Alert } from "@gonoodle/gn-universe-ui";

import { useContentTagQuery } from "../../hooks";
import api from "../../api";
import ContentTags from "../../components/ContentTags";
import SecondaryContentTags from "../../components/SecondaryContentTags";
import Layout from "../../components/Layout";
import { QUERY_KEYS, ROUTE_PERMISSIONS, ROUTE_PATHS } from "../../constants";
import { withAuthorization } from "../../containers";
import { FETCHERS } from "../../components/LayoutRenderer";

const ContentTagsPage = () => {
  const router = useRouter();
  const alert = Alert.useAlert();
  const { identity } = router.query;
  const [id] = identity;

  const {
    contentTag,
    isContentTagLoading,
    contentTagError,
  } = useContentTagQuery(id);

  if (isContentTagLoading) {
    return null;
  }

  if (contentTagError) {
    alert.publish("Something went wrong. Please reload page.");
    return null;
  }

  const isPrimaryContentTag = has(contentTag, "secondaryContentTags");

  return (
    <Layout
      pageTitle={contentTag.name}
      pageDescription="List of content tags"
      canonicalLinkPath={generatePath(ROUTE_PATHS.TAGS, {
        id,
        slug: contentTag.slug,
      })}
      progressBar
    >
      {isPrimaryContentTag ? (
        <ContentTags tag={contentTag} />
      ) : (
        <SecondaryContentTags contentTag={contentTag} />
      )}
    </Layout>
  );
};

export const getServerSideProps = withAuthorization(async (ctx) => {
  const {
    params: { identity },
  } = ctx;
  const [id] = identity;
  const client = api(ctx);
  const queryClient = new QueryClient();
  let contentTag = null;

  try {
    contentTag = await queryClient.fetchQuery(
      [QUERY_KEYS.CONTENT_TAG, id],
      () => client.getContentTag(id),
    );
  } catch (e) {
    if (e?.code === 410 || e?.code === 404) {
      return { notFound: true };
    }
  }

  const isPrimaryContentTag = has(contentTag, "secondaryContentTags");

  // Setting isPrimaryContentTag query to allow other parts of the app know what type of content tag is currently rendered
  // (listing page / landing page). This is currently used for analytics purpose.
  ctx.query.isPrimaryContentTag = isPrimaryContentTag;

  // TODO: Remove custom mapping once API is updated
  const shelvesConfiguration = contentTag.customShelves || [];

  const prefetchOperations = shelvesConfiguration.map((shelf) =>
    queryClient.prefetchQuery([FETCHERS.CUSTOM_SHELF, shelf.id], () =>
      client.getCustomShelf(shelf.id),
    ),
  );

  await Promise.all(prefetchOperations);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
}, ROUTE_PERMISSIONS.PUBLIC);

export default ContentTagsPage;
