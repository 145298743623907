import React from "react";
import PropTypes from "prop-types";
import { BlogCard, Columns, Alert } from "@gonoodle/gn-universe-ui";
import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { useRouter } from "next/router";
import { useContentTagBlogPostsQuery } from "../../hooks";
import { useLogEvent } from "../../contexts/Analytics";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

const TabBlog = ({ impressionsProps = {}, isActive, contentTagId }) => {
  const router = useRouter();
  const alert = Alert.useAlert();
  const [selectedBlog, setSelectedBlog] = React.useState(undefined);

  const {
    blogPosts = [],
    isLoadingBlogPosts,
    blogPostsError,
  } = useContentTagBlogPostsQuery(
    { slug: contentTagId },
    {
      enabled: isActive,
    },
  );

  const { isSuccess } = useLogEvent({
    event: "Blog Post Viewed",
    properties: {
      id: selectedBlog?.id,
      title: selectedBlog?.title.toLowerCase(),
      contentType: CONTENT_TYPES.BLOG_POST,
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      enabled: !!selectedBlog,
      referrer: impressionsProps,
    },
  });

  React.useEffect(() => {
    if (selectedBlog && isSuccess === true) {
      router.push(`/company/post/${selectedBlog.slug}`);
    }
  }, [isSuccess, router, selectedBlog]);

  if (blogPostsError) {
    alert.publish("Something went wrong. Please reload page.");

    return null;
  }

  return (
    <div role="tabpanel">
      <Columns title="Blogs" loading={isLoadingBlogPosts} cols={3} size="tall">
        {blogPosts.map(({ id, title, description, images }, index) => (
          <BlogCard
            key={id}
            title={title}
            description={description}
            imageUrl={images?.tile}
            onClick={() => setSelectedBlog(blogPosts[index])}
          />
        ))}
      </Columns>
    </div>
  );
};

TabBlog.propTypes = {
  impressionsProps: PropTypes.shape({
    sourcePage: PropTypes.string,
    sourcePageType: PropTypes.string,
    sourceName: PropTypes.string,
    sourceElement: PropTypes.string,
  }),
};

export default TabBlog;
