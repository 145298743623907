import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import {
  Header as LegacyHeader,
  Columns,
  Image,
  Alert,
} from "@gonoodle/gn-universe-ui";
import {
  PAGES_TYPES,
  SECTIONS,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import findIndex from "lodash/findIndex";
import omit from "lodash/omit";
import NextImage, { getImageProps } from "next/image";

import ShelvesTab from "./ShelvesTab";
import PrintableTile from "../PrintableTile";
import TabBlog from "./TabBlog";
import {
  IMPRESSION_PLACEMENT_TYPES,
  IMPRESSION_TYPES,
  HEADER_SIZES,
  ROUTE_PREFIX,
} from "../../constants";
import {
  useImpression,
  useContentTagGamesQuery,
  useContentTagPrintablesQuery,
} from "../../hooks";
import { WithContentLink } from "../../containers";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";
import PillTabs from "../PillTabs";
import nintendoHeroHeaderImage from "../../public/images/hero/tags/nintendo-hero-header.png";
import kidzBopHeroHeaderImage from "../../public/images/hero/tags/kidz-bop-hero-header.png";
import nickelodeonHeroHeaderImage from "../../public/images/hero/tags/nickelodeon-hero-header.png";

const VIDEOS = "videos";
const PRINTABLES = "printables";
const BLOG_POSTS = "blogPosts";
const GAMES = "games";

const tabs = [VIDEOS, PRINTABLES, BLOG_POSTS, GAMES];

const TileWithLink = WithContentLink("div");

function GameTile({ id, slug, duration, title, description, image, tagName }) {
  const router = useRouter();

  return (
    <TileWithLink
      id={id}
      slug={slug}
      contentLinkAsBase={`/${ROUTE_PREFIX.GAMES}`}
      contentLinkHrefBase={`/${ROUTE_PREFIX.GAMES}/[...identity]`}
      linkParams={{
        sourcePage: `${tagName} landing page`,
        sourcePageType: PAGES_TYPES.LANDING,
        sourceName: SECTIONS.ALL_GAMES,
        sourceElement: SECTIONS_TYPES.SHELF,
      }}
      events={[
        {
          event: "Link Clicked",
          properties: {
            sourcePage: `${tagName} landing page`,
            sourcePageType: PAGES_TYPES.LANDING,
            sourceName: SECTIONS.ALL_GAMES,
            sourceElement: SECTIONS_TYPES.SHELF,
            ...getUtmParamsFromQueryParams(router.query),
          },
        },
      ]}
    >
      {/* Thumbnail Container */}
      <div className="relative aspect-w-16 aspect-h-9 bg-gray-700 rounded group overflow-hidden transition-shadow shadow-darkSM">
        {/* Thumbnail */}
        <Image
          className="absolute inset-0 object-contain transform transition duration-200 group-hover:scale-110"
          sources={{
            "regular@1x": image,
            "regular@2x": image,
            "regular@3x": image,
          }}
          alt={title}
        />

        <div className="absolute flex justify-end">
          <span className="p-xs text-white gn-text-xs rounded bg-black bg-opacity-60 self-end mb-xs mr-xs transition-all">
            {duration}
          </span>
        </div>
      </div>

      <div className="flex flex-row mt-xs space-x-sm justify-between items-start">
        <div>
          <h3 className="gn-text-sm mt-xs text-white">{title}</h3>
        </div>
      </div>

      {description && (
        <div>
          <p className="gn-text-xs mt-xs text-gray-500">{description}</p>
        </div>
      )}
    </TileWithLink>
  );
}

function GamesTab({ tagName, contentTagSlug }) {
  const alert = Alert.useAlert();
  const { games = [], isLoadingGames, gamesError } = useContentTagGamesQuery(
    contentTagSlug,
  );

  if (gamesError) {
    alert.publish("Something went wrong. Please reload page.");

    return null;
  }

  return (
    <div role="tabpanel">
      <Columns title="All Games" loading={isLoadingGames} cols={5} size="short">
        {games.map(
          ({
            hashedId,
            slug,
            title,
            description,
            duration,
            selectionImage,
          }) => (
            <li className="relative group" key={`game-${hashedId}-${title}`}>
              <GameTile
                id={hashedId}
                slug={slug}
                title={title}
                description={description}
                duration={duration}
                image={selectionImage}
                tagName={tagName}
              />
            </li>
          ),
        )}
      </Columns>
    </div>
  );
}

function PrintablesTab({ contentTagSlug, linkParams, format }) {
  const alert = Alert.useAlert();
  const {
    printables = [],
    isPrintablesLoading,
    printablesError,
  } = useContentTagPrintablesQuery(contentTagSlug);

  if (printablesError) {
    alert.publish("Something went wrong. Please reload page.");
    return null;
  }

  return (
    <div role="tabpanel">
      <Columns
        title="All Activities"
        loading={isPrintablesLoading}
        cols={5}
        size="tall"
      >
        {printables.map(({ id, slug, title, image }) => (
          <li className="relative group" key={`printable-${id}-${title}`}>
            <PrintableTile
              id={id}
              slug={slug}
              mode="dark"
              sources={image}
              title={title}
              linkParams={linkParams}
              format={format}
            />
          </li>
        ))}
      </Columns>
    </div>
  );
}

function Header({ images }) {
  const { original16x9, original32x9 } = images;
  const commonImageConfig = { sizes: "100vw", alt: "", priority: true };

  const { props: wideImageProps } = getImageProps({
    ...commonImageConfig,
    src: original32x9,
    width: 5760,
    height: 1620,
  });

  const { props: tallImageProps } = getImageProps({
    ...commonImageConfig,
    src: original16x9,
    width: 5760,
    height: 3240,
  });

  const commonImageProps = omit(wideImageProps, ["srcSet", "width", "height"]);

  return (
    <div className="aspect-w-16 aspect-h-9 lg:aspect-w-[32] overflow-hidden">
      <picture>
        <source media="(min-width: 1024px)" srcSet={wideImageProps.srcSet} />
        <source media="(max-width: 1023px)" srcSet={tallImageProps.srcSet} />

        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img className="w-full h-auto" {...commonImageProps} />
      </picture>
    </div>
  );
}

const SecondaryContentTags = ({ contentTag }) => {
  const router = useRouter();
  const { availableTabs, adProductPartners } = contentTag;
  const defaultTab = availableTabs?.videos
    ? VIDEOS
    : availableTabs?.games
    ? GAMES
    : availableTabs?.printables
    ? PRINTABLES
    : BLOG_POSTS;
  const { identity, tab = defaultTab } = router.query;
  const defaultTabIndex = React.useMemo(() => {
    const tabIndex = findIndex(tabs, (tabName) => tabName === tab);
    return tabIndex !== -1 ? tabIndex : 0;
  }, [tab]);
  const selectedTab = tabs[defaultTabIndex];
  const [id] = identity;
  const rotatedAdProduct = adProductPartners[0];

  const handleTabChange = (value) => {
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, tab: tabs[value] },
      },
      undefined,
      { shallow: true },
    );
  };

  const partnerHeaderInViewRef = useImpression({
    properties: {
      impression_type: IMPRESSION_TYPES.LOGO,
      placement: IMPRESSION_PLACEMENT_TYPES.CONTENT_HUB_HEADER,
      ad_product_type: rotatedAdProduct?.type,
      partner_name: rotatedAdProduct?.partnerName,
      partner_id: rotatedAdProduct?.partnerId,
      ad_product_partner_id: rotatedAdProduct?.id,
      content_hub_type: contentTag?.type,
      content_hub_name: contentTag?.name,
    },
    enabled: !!rotatedAdProduct,
  });

  return (
    <>
      <div ref={partnerHeaderInViewRef}>
        {["a2vyrX", "QXBk42"].includes(id) ? (
          <NextImage
            className="w-full object-contain"
            src={nintendoHeroHeaderImage}
            quality={100}
            alt="Nintendo Hero Header"
          />
        ) : ["DYLe7X"].includes(id) ? (
          <NextImage
            className="w-full object-contain"
            src={kidzBopHeroHeaderImage}
            quality={100}
            alt="KidzBop Hero Header"
          />
        ) : ["0YZAdw"].includes(id) ? (
          <NextImage
            className="w-full object-contain"
            src={nickelodeonHeroHeaderImage}
            quality={100}
            alt="Nickelodeon Hero Header"
          />
        ) : contentTag.images?.headerImage?.original32x9 &&
          contentTag.images?.headerImage?.original16x9 ? (
          <Header images={contentTag.images?.headerImage} />
        ) : (
          <LegacyHeader
            bgSources={contentTag.images?.headerImage}
            title={contentTag.name}
            size={HEADER_SIZES[contentTag.headerImageSize]}
            description={contentTag.description}
            tagLogoSources={contentTag.images?.logo}
            mode={contentTag.textTheme}
            sponsorLogo={rotatedAdProduct?.images?.partnerContentHubHeaderLogo}
            sponsorName={rotatedAdProduct?.partnerName}
            sponsorUrl={rotatedAdProduct?.partnerUrl}
          />
        )}
      </div>

      <div className="pt-md">
        <PillTabs
          key={contentTag.id}
          defaultTab={defaultTabIndex}
          onTabChange={handleTabChange}
          role="tablist"
        >
          <ShelvesTab
            key="ShelvesTab"
            tag={contentTag}
            label="Videos"
            hidden={!availableTabs?.videos}
          />

          <PrintablesTab
            key="TabPrintables"
            label="Activities"
            hidden={!availableTabs?.printables}
            contentTagSlug={id}
            linkParams={{
              sourcePage: `${contentTag.name} landing page`,
              sourcePageType: PAGES_TYPES.LANDING,
              sourceName: SECTIONS.ALL_ACTIVITIES,
              sourceElement: SECTIONS_TYPES.SHELF,
            }}
            format="portrait"
          />

          <TabBlog
            key="TabBlog"
            label="Blog Posts"
            isActive={selectedTab === BLOG_POSTS}
            contentTagId={id}
            hidden={!availableTabs?.blogPosts}
            impressionsProps={{
              sourcePage: `${contentTag.name} landing page`,
              sourcePageType: PAGES_TYPES.LANDING,
              sourceName: SECTIONS.ALL_BLOG_POSTS,
              sourceElement: SECTIONS_TYPES.SHELF,
            }}
          />

          <GamesTab
            key="TabGames"
            label="Games"
            contentTagSlug={id}
            hidden={!availableTabs?.games}
            tagName={contentTag.name}
          />
        </PillTabs>
      </div>
    </>
  );
};

SecondaryContentTags.propTypes = {
  contentTag: PropTypes.shape({
    availableTabs: PropTypes.shape({
      featured: PropTypes.bool,
      videos: PropTypes.bool,
      printables: PropTypes.bool,
      blogPosts: PropTypes.bool,
    }),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    images: PropTypes.shape({
      header: PropTypes.shape({
        "regular@1x": PropTypes.string,
        "regular@2x": PropTypes.string,
        "regular@3x": PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default SecondaryContentTags;
